import React from "react";
import { Link } from 'react-router-dom'

// MUI
import { Box, Container, Divider, List, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import {  } from '@mui/icons-material'

export default function Footer(){

    return(
        <Box sx={{backgroundColor: 'secondary.main'}} padding={2} pt={3} pb={3} >
            <Container>
                <Stack direction={{xs: 'column', md: 'row'}} justifyContent='space-between' mb={3} spacing={3} >
                    <Box>
                        <Typography fontSize={30} fontWeight='bold' color='white' >S&G DESIGN</Typography>
                        <Typography fontWeight='medium' fontSize={14} letterSpacing={25.5} color='white' mb={2} >STUDIO</Typography>

                        <Typography fontSize={14} fontWeight='medium' color='white' >Welcome to S&G, where spaces are more than just</Typography>
                        <Typography fontSize={14} fontWeight='medium' color='white' >spaces - they're a testament to theextraordinary</Typography>
                        <Typography fontSize={14} fontWeight='medium' color='white' >power of design.</Typography>
                    </Box>
                    <Box>
                        <Typography color='white' fontWeight='medium' fontSize={17} >Company</Typography>
                        <Typography fontSize={14} mt={1} ><Link to='/' style={{color: '#fafafa'}} >Home</Link></Typography>
                        <Typography fontSize={14} mt={1} ><Link to='/' style={{color: '#fafafa'}} >Gallery</Link></Typography>
                        <Typography fontSize={14} mt={1} ><Link to='/' style={{color: '#fafafa'}} >About</Link></Typography>
                        <Typography fontSize={14} mt={1} ><Link to='/' style={{color: '#fafafa'}} >Contact</Link></Typography>
                    </Box>
                    <Box>
                        <Typography color='white' fontWeight='medium' fontSize={17} >Take a walk</Typography>
                        <Typography fontSize={14} mt={1} color='white' >Durgasthan 1no colony</Typography>
                        <Typography fontSize={14} mt={1} color='white' >Katihar - 854105</Typography>
                        <Typography fontSize={14} mt={1} color='white' >Bihar, India</Typography>
                    </Box>
                </Stack><Divider/>
                <Stack mt={3} direction={{xs: 'column', md: 'row'}} justifyContent='space-between' spacing={2} >
                    <Typography color='white' fontSize={14} >&#169; Copyright S&G Design Studio</Typography>

                    <a href="https://passionfrom.com" target="_blank"><img src="https://passionfrom.com/passionfromassets/whitePassionfrom.png" style={{width: 120}} /> </a>
                </Stack>
            </Container>
        </Box>
    );
}