import React, { useState } from "react";
import {  } from 'react-router-dom'

// MUI
import { Box, Button, Container, Grid, IconButton, ImageList, ImageListItem, List, Stack, Typography } from '@mui/material'
import { Facebook, Instagram, WhatsApp } from '@mui/icons-material'

import Footer from "./footer";

export default function Home(){

    const [projectimages] = useState([
        {name: '1.webp'},
        {name: '2.webp'},
        {name: '2.webp'},
        {name: '1.webp'},
        {name: '1.webp'},
        {name: '2.webp'}
    ])

    return(
        <Box>
            <Container>
                <Grid direction={{md: 'row', xs: 'column-reverse'}} container mt={2} spacing={3} >
                    <Grid item xs={12} md={7} display='flex' justifyContent='left' alignItems='center' >
                        <Box>
                            <Typography fontWeight='bold' >We are</Typography>
                            <Typography fontSize={50} fontWeight='bolder' >ARCHITECTS &</Typography>
                            <Typography fontSize={50} fontWeight='bolder' >DESIGNERS</Typography>
                            <Typography fontWeight='light' color='gray' mt={2} fontSize={14} >At S&G, we transcend the ordinary, turning spaces into living dreams</Typography>
                            <Typography fontWeight='light' color='gray' fontSize={14} mb={3} >through the art of architecture, interior design, furniture curation, and landscaping.</Typography>

                            <Button variant="contained" color="secondary" sx={{borderRadius: 0}} >WRITE AN E-MAIL</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <img src="/assets/sgdesign1.webp" alt="sgdesign" />
                    </Grid>
                </Grid>
            </Container>

            <Container>
                <Grid container mt={6} spacing={3} >
                    <Grid item xs={12} md={4.5}>
                        <img src="/assets/sgdesign2.webp" alt="sgdesign2" style={{borderRadius: 10}} />
                    </Grid>
                    <Grid item xs={12} md={7.5} display='flex' justifyContent='left' alignItems='center' >
                        <Box>
                            <Typography fontWeight='bold' fontSize={40} >Elevating Spaces, Enriching Lives: S&G's Artistry in Design.</Typography>
                            <Typography fontWeight='light' fontSize={14} mt={3} color='gray' >At S&G, we transcend the ordinary, turning spaces into living dreams through the art of architecture, interior design, furniture curation, and landscaping. Our passion lies in the creation of harmonious environments that not only captivate the eye but also elevate the soul. With a commitment to innovation and an unwavering dedication to quality, we are your partners in transforming spaces into stories.</Typography>

                            <Stack mt={3} spacing={3} direction='row' useFlexGap justifyContent={{xs: 'space-evenly', md: 'space-between'}} flexWrap='wrap' >
                                <Box sx={{border: 'thin solid #cccccc'}} padding={2} borderRadius={5} >
                                    <Typography fontSize={70} fontWeight='bold' color='secondary' >17+</Typography>
                                    <Typography color='gray' fontSize={14} >Years of experience</Typography>
                                </Box>
                                <Box sx={{border: 'thin solid #cccccc'}} padding={2} borderRadius={5} >
                                    <Typography fontSize={70} fontWeight='bold' color='secondary' >70+</Typography>
                                    <Typography color='gray' fontSize={14} >Project completed</Typography>
                                </Box>
                                <Box sx={{border: 'thin solid #cccccc'}} padding={2} borderRadius={5} >
                                    <Typography fontSize={70} fontWeight='bold' color='secondary' >35+</Typography>
                                    <Typography color='gray' fontSize={14} >Experienced person</Typography>
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>

                <Box sx={{backgroundColor: 'secondary.main'}} padding={0.1} mt={3} mb={3} ></Box>
                <Grid container spacing={3} >
                    <Grid item sx={12} md={4.5}>
                        <img src="/assets/sgdesign3.webp" alt="sgdesign3" style={{borderRadius: 10}} />
                    </Grid>
                    <Grid item sx={12} md={7.5} display='flex' justifyContent='start' alignItems='center' >
                        <List>
                            <Typography fontSize={20} fontWeight='bold' mb={1} >Our expertise</Typography>
                            <Box>
                                <Typography fontSize={16} fontWeight='bold' >[ Architecture ]</Typography>
                                <Typography fontSize={14} color='gray' >Our architectural prowess extends beyond blueprints and structures; it's about crafting narratives that merge form and function seamlessly. Each building we design is a manifestation of creativity, a blend of aesthetics and purpose that speaks to the essence of the surroundings.</Typography>
                            </Box>
                            <Box mt={2} >
                                <Typography fontSize={16} fontWeight='bold' >[ Interior Design ]</Typography>
                                <Typography fontSize={14} color='gray' >Infusing life into empty spaces is our specialty. Our interior designs narrate tales of comfort, elegance, and individuality. We harmonize color palettes, textures, and furnishings to create interiors that resonate with the occupants' personalities and aspirations.</Typography>
                            </Box>
                            <Box mt={2} >
                                <Typography fontSize={16} fontWeight='bold' >[ Furniture Curation ]</Typography>
                                <Typography fontSize={14} color='gray' >Furniture isn't just about utility; it's an expression of character. Our handpicked furniture pieces are more than objects; they're statements that contribute to the overall design narrative. From classic elegance to modern minimalism, we curate pieces that complete the visual symphony of your space.</Typography>
                            </Box>
                            <Box mt={2} >
                                <Typography fontSize={16} fontWeight='bold' >[ Landscaping ]</Typography>
                                <Typography fontSize={14} color='gray' >Nature is the canvas, and we are the artists. Our landscaping expertise breathes life into exteriors, weaving together elements that transform outdoor spaces into breathtaking escapes. Whether it's a serene garden, a vibrant terrace, or a communal green, we create outdoor havens that resonate with tranquility and beauty.</Typography>
                            </Box>
                        </List>
                    </Grid>
                </Grid>
                <Box sx={{backgroundColor: 'secondary.main'}} padding={0.1} mt={3} mb={3} ></Box>

                <Stack direction='row' justifyContent='space-between' mb={3} >
                    <Button variant="text" color="secondary" >Discover our masterpiece projects</Button>
                    <Button variant="outlined" color="secondary" >View all</Button>
                </Stack>
                
                <ImageList variant="masonry" cols={3} gap={10}>
                    {projectimages.map((item, i) => (
                        <ImageListItem key={i}>
                        <img
                            src={`/assets/projects/${item.name}?w=1000&fit=crop&auto=format`}
                            srcSet={`/assets/projects/${item.name}?w=1000&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.name}
                            loading="lazy"
                            style={{borderRadius: 10}}
                        />
                        </ImageListItem>
                    ))}
                </ImageList>

                <Box display='flex' justifyContent='center' alignItems='center' sx={{height: '400px', backgroundImage: "url('/assets/sgdesign4.webp')", backgroundSize: 'cover', backgroundPosition: 'center center'}} borderRadius={5} mt={3} >
                    <Box textAlign='center' >
                        <Typography fontSize={{xs: 40, md: 60}} color='white' fontWeight='bold' >Have a Dream Project?</Typography>
                        <Typography color='white' fontWeight='medium' fontSize={14}>" Let’s talk to us, share your dream project and we will transform it into reality "</Typography>
                        <Box textAlign='center' mt={3} >
                            <Button color="primary" variant="contained" size="large" sx={{borderRadius: 0}} >Contact us</Button>
                        </Box>
                    </Box>
                </Box>

                <Grid container spacing={3} mt={3} mb={5} >
                    <Grid item xs={12} md={4.5} >
                        <img src="/assets/sgdesign5.webp" alt="sgdesign5" style={{borderRadius: 20}} />
                    </Grid>
                    <Grid item xs={12} md={7.5} display='flex' justifyContent='start' alignItems='center' >
                        <Box>
                            <Typography fontSize={30} fontWeight='bold' >Join us in Redefining Design</Typography>
                            <Typography fontSize={14} color='gray' mt={2} >Step into a world where design knows no boundaries and imagination knows no limits. S&G is not just a design company; we are your partners in shaping the environments where life's moments unfold.</Typography>

                            <Typography fontSize={20} fontWeight='medium' mt={3} >Get in touch with us...</Typography>

                            <Stack direction='row' >
                                <IconButton color="secondary" size="large" ><Facebook fontSize="large" /></IconButton>
                                <IconButton color="secondary" size="large" ><Instagram fontSize="large" /></IconButton>
                                <IconButton color="secondary" size="large" ><WhatsApp fontSize="large" /></IconButton>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>

            </Container>
        <Footer/> </Box>
    );
}