import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './index.css';

// MUI
import { createTheme, ThemeProvider } from '@mui/material/styles';


// PUBLIC
import Header from './page/header';
import Home from './page';

function SGdesign(){
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1300,
        xl: 1920
      },
    },

    palette: {
      primary: {
        light: '#D3B87C',
        main: '#CCAB66',
        dark: '#B48E3C',
        contrastText: '#FFFFFF',
      },
      secondary: {
        light: '#899446',
        main: '#575e2c',
        dark: '#3E431F',
        contrastText: '#FFFFFF',
      }
    },
    typography: {
      button: {
        textTransform: 'capitalize'
      },
      fontFamily: [
        'open sans',
        'sans-serif'
      ].join(',')
    },

    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none'
          }
        }
      }
    }
  });

  return(
    <ThemeProvider theme={theme}>
      <Routes>
        <Route element={<Header />}>
          <Route path='/' element={<Home />} />

        </Route>
      </Routes>
    </ThemeProvider>
  );
}
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<BrowserRouter><SGdesign/></BrowserRouter>)