import React, { useState } from "react";
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom'

// MUI
import { AppBar, Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Toolbar, Typography } from '@mui/material'
import { ArrowOutward, Menu } from '@mui/icons-material'

export default function Header(){
    const navi = useNavigate()

    const [links] = useState([
        {name: 'Home', link: '.'},
        {name: 'Products', link: '/products'},
        {name: 'About', link: '/about'},
        {name: 'Contact', link: '/contact'}
    ])

    const [open, setOpen] = useState(false)

    return(
        <>
        <AppBar position='sticky' elevation={0} color='primary' >
            <Toolbar>
                <Box flexGrow={1} >
                    <Typography fontWeight='bold' fontSize={25} >S&G Design</Typography>
                </Box>
                <Box>
                    <Stack direction='row' spacing={7} sx={{display: {xs: 'none', md: 'block'}}} >
                        { links.map((data, i) =>
                        <NavLink to={data.link} key={i} style={{color: '#fafafa', fontFamily: 'sans-serif'}} >{data.name}</NavLink> ) }
                    </Stack>
                    <Box sx={{display: {xs: 'block', md: 'none'}}} >
                        <IconButton onClick={() => setOpen(true)} ><Menu/></IconButton>
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
        <Outlet />
        
        <Drawer open={open} onClose={() => setOpen(false)} anchor='bottom' PaperProps={{style: {borderTopLeftRadius: 15, borderTopRightRadius: 15, paddingTop: 10}}} >
            <List>
                { links.map((data, i) =>
                <ListItem key={i} >
                    <ListItemButton onTouchEnd={() => setOpen(false)} >
                        <ListItemIcon><ArrowOutward /></ListItemIcon>
                        <ListItemText onClick={() => navi(data.link)} >{data.name}</ListItemText>
                    </ListItemButton>
                </ListItem> ) }
            </List>
        </Drawer>
        </>
    );
}